import { MissionCode, MissionTaskStatus } from 'service/user';
import useStore from 'store/timeline';
import styles from './SBIBanner.module.scss';
import { useMemo } from 'react';
import { track } from '@amplitude/analytics-browser';
import classNames from 'classnames';

const randomBgUrl = () => {
  // const random = Math.floor(Math.random() * 3);
  // if (random === 1) return '/images/banners/ig_banner_05_07_1.png';
  // if (random === 2) return '/images/banners/ig_banner_05_07_2.png';
  return '/images/banners/sbi_mission_banner_pr.jpg';
};

const SBIBanner = ({
  fallbackElement,
  className,
}: {
  fallbackElement?: JSX.Element;
  className?: string;
}) => {
  const allMissions = useStore((state) => state.allMissions);
  const sbiMission = useMemo(() => {
    return allMissions.find(
      (mission) => mission.code === MissionCode.SBINeoCreateAccount
    );
  }, [allMissions]);
  const showingBanner =
    sbiMission &&
    [MissionTaskStatus.NotYet, MissionTaskStatus.InProgress].includes(
      sbiMission?.current_task?.status
    );
  const bgUrl = useMemo(() => {
    return randomBgUrl();
  }, []);

  if (!showingBanner || !bgUrl)
    return fallbackElement ? fallbackElement : <></>;
  return (
    <div
      className={classNames(styles.banner, className ?? '')}
      onClick={() => {
        track('Click Top Banner', { banner_type: 'sbi_banner' });
        window.open(sbiMission?.link, '_blank')?.focus();
      }}
    >
      <img src={bgUrl} width="100%" />
    </div>
  );
};

export default SBIBanner;
